import React from 'react'
import styled from "styled-components"
import { Container, Table } from "react-bootstrap"
import Img from "gatsby-image"

const RatesTable = ({title, data, cleaningFee}) => {
  return (
     <RatesContainer>
    
      <h2>{title}</h2>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Dates</th>
            <th>Season</th>
            <th>Price</th>
          </tr>
        </thead>
         {data.map((item, index) => (
        <tbody key={index}>
          <tr>
            <td>{item.date}</td>
            <td>{item.season}</td>
            <td>{item.price}</td>
          </tr>
        </tbody>
        ))}
      </Table>
      <p>*Cleaning Fee: {cleaningFee}</p>
      <p>5 Nights Minimum Stay in June & September</p>
      <p>7 Nights Minimum Stay in July & August</p>
    </RatesContainer>
  )
}

export default RatesTable

const RatesContainer = styled(Container)`
  color: #fff;
  margin-bottom: 2rem;
  font-family: 'Nunito', sans-serif;

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
`

const Image = styled(Img)`
  border-radius: 50%;
  width: 250px;
  height: 250px;
  align-self: center;
  margin: auto;
  margin-bottom: 2rem;
`