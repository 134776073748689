import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import RatesTables from "../components/rates"

const Rates = () => {  

  return (
    <Layout>
      <SEO title="Gallery" />
      <CabinsFullWidth />
      <RatesTables />
    </Layout>
  )
}

export default Rates