export const hidawayRatesData = [
 
  {
    date: "Nov. 15 - Mar. 4",
    season: "Storm",
    price: "$185"
  },
  {
    date: "Mar. 5 - Jun. 10",
    season: "Spring",
    price: "$250"
  },
  {
    date: "Jun. 11 - Sept. 30",
    season: "Summer",
    price: "$325"
  },
  {
    date: "Oct. 1 - Nov. 14",
    season: "Fall",
    price: "$250"
  },
  {
    date: "Dec. 17 - Jan. 3",
    season: "Holiday",
    price: "$295"
  }  
]
  