import React from "react"
import styled from "styled-components"
import PageHeader from "../elements/pageHeader"
import RatesTable from "../elements/ratesTable"
import { Link } from 'gatsby'
import {shipwreckRatesData} from "../data/shipwreckRatesData"
import {hidawayRatesData} from "../data/hidawayRatesData"
import ContactInfo from "./contactInfo"

const Rates = () => {  


  return (
    <>
    <PageHeader header="Rates" />
      <RatesContainer>
        <RatesTablesContainer>        
          <CabinLink to="/shipwreckCabin"><RatesTable title="Shipwreck Cabin" data={shipwreckRatesData} cleaningFee="$75.00" /></CabinLink>
          <CabinLink to="/haidawayCottage"><RatesTable title="Haidaway Cottage" data={hidawayRatesData} cleaningFee="$75.00" /></CabinLink>
        </RatesTablesContainer>
        <RatesInfo>
        <p>*All rates are based on double occupancy. Each additional guest is $25 per night</p>
        <p>**Pets: Only one small friendly pet accepted at a $50 fee.</p>
        <h5><span>***Statutory holiday's and holiday weekends rent at our holiday rates regardless of the time of year</span></h5>
        </RatesInfo>
      </RatesContainer>
      <ContactInfo/>
    </>
  )
}

export default Rates

const RatesContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  span {
    font-weight: 600;
  }
`

const RatesTablesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
  }
`

const RatesInfo = styled.div`
  padding: 5px;
  margin: 5px;
`

const CabinLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`